export const mainGrid = 'Атом'
export const typeKP = 'КП';
export const statusEnd = 'Завершён';
export const statusClose = 'Прекращён';
export const columnsProjects = ['id', 'subject', 'startDate', 'factStartDate', 'dueDate', 'factDueDate', 'duration', 'factDuration'];

export const statusStart = 'Начата';
export const customFiledIdWork = 'ID работы';
export const columnsStage = ['id', 'subject', 'dueDate'];


export const proejctTarget = 'Совещания и поручения';
export const columnsConf = ['id', 'subject', 'startDate', 'dueDate', 'factStartDate', 'factDueDate'];

export const customFiledManager = 'Ответственный менеджер СУОФ';
export const customFiledConf = 'Предприятие';
export const proejctMain = 'sibur';

// Worker
export let workerProjects = {
  config:
  {
    _type: "GridWidget",
    identifier: "work_packages_table",
    startColumn: 1,
    endColumn: 2,
    startRow: 1,
    endRow: 2,
    options: {
      name: "Проекты",
      queryId: '',
    },
  }
};

export let workerStage = {
  config:
  {
    _type: "GridWidget",
    identifier: "work_packages_table",
    startColumn: 1,
    endColumn: 2,
    startRow: 2,
    endRow: 3,
    options: {
      name: "Основные вехи по проектам",
      queryId: '',
    },
  },
  gap: { type: 'row' }
};

export let workerConference = {
  config:
  {
    _type: "GridWidget",
    identifier: "work_packages_table",
    startColumn: 1,
    endColumn: 2,
    startRow: 3,
    endRow: 4,
    options: {
      name: "Совещания и поручения",
      queryId: '',
    },
  }
};

export let workerActivity = {
  config:
  {
    _type: "GridWidget",
    identifier: "event_activity",
    startColumn: 2,
    endColumn: 3,
    startRow: 3,
    endRow: 4,
    options: {
      name: "Деятельность по проектам",
    },
  },
  gap: { type: 'column' }
};

// Manager
export let managerProjects = {
  config:
  {
    _type: "GridWidget",
    identifier: "projects_list",
    startColumn: 1,
    endColumn: 2,
    startRow: 1,
    endRow: 2,
    options: {
      name: "Список проектов",
    },
  }
};

export let managerTeam = {
  config:
  {
    _type: "GridWidget",
    identifier: "team",
    startColumn: 2,
    endColumn: 3,
    startRow: 1,
    endRow: 2,
    options: {
      name: "Команда",
    },
  },
  gap: { type: 'column' }
};


export let managerInWork = {
  config:
  {
    _type: "GridWidget",
    identifier: "work_packages_table",
    startColumn: 1,
    endColumn: 2,
    startRow: 2,
    endRow: 3,
    options: {
      name: "Проекты в работе по менеджерам СУОФ",
      queryId: '',
    },
  }
};

export let managerConference = {
  config:
  {
    _type: "GridWidget",
    identifier: "work_packages_table",
    startColumn: 1,
    endColumn: 2,
    startRow: 3,
    endRow: 4,
    options: {
      name: "Совещания и поручения",
      queryId: '',
    },
  },
};

export let managerActivity = {
  config:
  {
    _type: "GridWidget",
    identifier: "event_activity",
    startColumn: 1,
    endColumn: 2,
    startRow: 4,
    endRow: 5,
    options: {
      name: "Деятельность по проектам",
    },
  }
};

export const managerStageStatus = ['В работе план', 'В работе отставание', 'В работе ошибка'];
export const managerStage = ['id', 'subject'];
export const workerWidgets = [workerProjects, workerStage, workerConference, workerActivity];
export const managerWidgets = [managerProjects, managerTeam, managerInWork, managerConference, managerActivity];